import { useRouter } from 'next/router'
import React from 'react'

import { useChatsActions } from '@features/chats/reducers/ChatsReducers'
import { useProfileActions } from '@features/profile/reducers'

import { PATHS } from '@constants/paths'

import { useCandidateSearchActions } from '@store/candidate/search/slice'

const ResetChatFilter = ({ children }: { children?: React.ReactNode }) => {
  const router = useRouter()
  const chatsActions = useChatsActions()
  const profileActions = useProfileActions()
  const candidateSearchActions = useCandidateSearchActions()

  React.useEffect(() => {
    const handleRouteChangeComplete = (nextPath: string) => {
      if (!nextPath.startsWith(PATHS.CHAT)) {
        chatsActions.resetFilterOptions()
      }
      if (!nextPath.startsWith(PATHS.EDIT_PROFILE)) {
        profileActions.setEditProfilePage('about-user')
      }
      if (!nextPath.startsWith(PATHS.SEARCH_CANDIDATE)) {
        candidateSearchActions.resetCandidateSearchFilter()
        candidateSearchActions.setJobId('')
      }
    }
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}

export default ResetChatFilter
