import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { JobApplicantsFilters } from '@features/applications/reducers/types'

import { JobListingFilter } from '@custom-types/components/job-listing-filter'
import { JobPost } from '@custom-types/job-post'

type ShowInviteToApplyDrawerType = {
  isOpen: boolean
  candidateId: string
}

type CreateTemplateModalType = {
  isOpen: boolean
  isEdit: boolean
  message?: string
  templateId?: string
}

export type CandidateSearchFilter = Omit<
  JobApplicantsFilters,
  'searchText' | 'page' | 'isFavourited' | 'sortOption' | 'sortType'
> & {
  uniqueTimestamp?: number
}

type CandidateSearchState = {
  showCandidateDetailDrawer: boolean
  showCandidateInviteToInterview: boolean
  candidateId: string
  showInviteToApplyDrawer: ShowInviteToApplyDrawerType
  selectedJob: JobPost | undefined
  showCreateTemplateModal: CreateTemplateModalType
  filters: CandidateSearchFilter
  jobId: string
  jobPostPresetFilterApplied: boolean
  openFilterModal: boolean
  jobListingFilterModal: JobListingFilter
}

const initialState: CandidateSearchState = {
  candidateId: '',
  showCandidateDetailDrawer: false,
  showCandidateInviteToInterview: false,
  showInviteToApplyDrawer: {
    candidateId: '',
    isOpen: false,
  },
  selectedJob: undefined,
  showCreateTemplateModal: {
    isOpen: false,
    isEdit: false,
  },
  jobId: '',
  filters: {
    uniqueTimestamp: Date.now(),
  },
  jobPostPresetFilterApplied: false,
  openFilterModal: false,
  jobListingFilterModal: {
    query: {
      page: 1,
    },
    tab: 'active',
    open: false,
  },
}

export const candidateSearchSlice = createSlice({
  name: 'candidateSearch',
  initialState,
  reducers: {
    setSearchJobListingModalQueryState(
      state,
      action: PayloadAction<
        Partial<CandidateSearchState['jobListingFilterModal']['query']>
      >
    ) {
      state.jobListingFilterModal.query = {
        ...state.jobListingFilterModal.query,
        ...action.payload,
      }
    },
    changeSearchJobListingModalTab(
      state,
      action: PayloadAction<
        CandidateSearchState['jobListingFilterModal']['tab']
      >
    ) {
      state.jobListingFilterModal = {
        ...state.jobListingFilterModal,
        query: {
          page: 1,
        },
        tab: action.payload,
      }
    },
    setOpenJobListingFilterModal(state, action: PayloadAction<boolean>) {
      state.jobListingFilterModal.open = action.payload
    },
    setQueryJobListingFilterModal(
      state,
      action: PayloadAction<
        Partial<CandidateSearchState['jobListingFilterModal']['query']>
      >
    ) {
      state.jobListingFilterModal.query = {
        ...state.jobListingFilterModal.query,
        ...action.payload,
      }
    },
    setTabJobListingFilterModal(
      state,
      action: PayloadAction<
        CandidateSearchState['jobListingFilterModal']['tab']
      >
    ) {
      state.jobListingFilterModal.tab = action.payload
    },
    setCandidateDetailDrawerState(
      state,
      action: PayloadAction<
        Partial<{
          showCandidateDetailDrawer: boolean
          candidateId: string
        }>
      >
    ) {
      const { candidateId, showCandidateDetailDrawer } = action.payload
      state.candidateId =
        candidateId !== undefined ? candidateId : state.candidateId
      state.showCandidateDetailDrawer =
        showCandidateDetailDrawer !== undefined
          ? showCandidateDetailDrawer
          : state.showCandidateDetailDrawer
    },
    resetCandidateDetailDrawerState(state) {
      state.candidateId = initialState.candidateId
      state.showCandidateDetailDrawer = initialState.showCandidateDetailDrawer
    },
    setCandidateInviteToInterview(
      state,
      action: PayloadAction<
        Partial<{
          showCandidateInviteToInterview: boolean
          candidateId: string
        }>
      >
    ) {
      const { candidateId, showCandidateInviteToInterview } = action.payload
      state.showCandidateInviteToInterview =
        showCandidateInviteToInterview !== undefined
          ? showCandidateInviteToInterview
          : state.showCandidateInviteToInterview
      state.candidateId =
        candidateId !== undefined ? candidateId : state.candidateId
    },
    resetCandidateInviteToInterview(state) {
      state.candidateId = initialState.candidateId
      state.showCandidateInviteToInterview =
        initialState.showCandidateInviteToInterview
    },
    setInviteToApplyDrawerState(
      state,
      action: PayloadAction<{
        showInviteToApplyDrawer: boolean
        candidateId: string
      }>
    ) {
      state.showInviteToApplyDrawer = {
        isOpen: action.payload.showInviteToApplyDrawer,
        candidateId: action.payload.candidateId,
      }
    },
    resetInviteToApplylDrawerState(state) {
      state.showInviteToApplyDrawer = initialState.showInviteToApplyDrawer
    },
    setShowCreateTemplateModal(
      state,
      action: PayloadAction<CandidateSearchState['showCreateTemplateModal']>
    ) {
      state.showCreateTemplateModal = action.payload
    },
    setCandidateSearchFilter(
      state,
      action: PayloadAction<CandidateSearchState['filters']>
    ) {
      state.filters = {
        ...action.payload,
        uniqueTimestamp: Date.now(),
      }
    },
    resetCandidateSearchFilter(state) {
      state.filters = { ...initialState.filters, uniqueTimestamp: Date.now() }
    },
    setJobId(state, action: PayloadAction<string>) {
      state.jobId = action.payload
    },
    setJobPostPresetFilterApplied(state, action: PayloadAction<boolean>) {
      state.jobPostPresetFilterApplied = action.payload
    },
    setOpenFilterModal(state, action: PayloadAction<boolean>) {
      state.openFilterModal = action.payload
    },
  },
})

const { actions } = candidateSearchSlice

export const useCandidateSearchActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}
