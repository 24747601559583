import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type StateProps = {
  page:
    | 'basic-info'
    | 'location-form'
    | 'candidate-requirements'
    | 'review'
    | 'select-assessment'
    | 'supporting-visual-media'
    | 'selecting-package'
}

const initialState: StateProps = {
  page: 'basic-info',
}

const createJobSlice = createSlice({
  name: 'create-job',
  initialState,
  reducers: {
    setPage: (state, actions: PayloadAction<StateProps['page']>) => {
      state.page = actions.payload
    },
    resetState: (state) => {
      state.page = 'basic-info'
    },
  },
})

export const createJobActions = createJobSlice.actions

export default createJobSlice.reducer
