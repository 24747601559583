export enum CandidateSortOptions {
  CANDIDATE_NAME = 'CandidateName',
  TOTAL_WORK_EXP = 'TotalWorkExperience',
  CHANNEL_CREATED_DATE = 'ChannelCreatedDate',
  RESPONSE_RATE = 'LatestActivity',
  NEAREST_CANDIDATE_LOCATION = 'NearestCandidateLocation',
}

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}
